import { serializeDeliveryTime } from '@/serializers/deliveryTimeSerializer';
import { serializeDeliveryTariff } from '@/serializers/deliveryTariffSerializer';
import stc from 'string-to-color';

export class DeliveryAreaWithTariff {
  constructor(deliveryAreaData) {
    this.areaKeys = deliveryAreaData
      ? deliveryAreaData.area_keys : [];
    this.deliveryTariff = deliveryAreaData
      ? serializeDeliveryTariff(deliveryAreaData.delivery_tariff) : null;
    this.deliveryTime = deliveryAreaData
      ? serializeDeliveryTime(deliveryAreaData.delivery_time) : null;
    this.isReducedArea = deliveryAreaData.is_reduced_area || false;
  }

  get tariffKey() {
    return this.deliveryTariff.pk + this.deliveryTime.pk;
  }

  get tariffTitle() {
    return `${this.deliveryTariff.title} (${this.deliveryTime.title})`;
  }

  get tariffColor() {
    return stc(this.tariffKey + this.deliveryTariff.title + this.deliveryTime.title);
  }
}

export const serializeDeliveryArea = (area) => new DeliveryAreaWithTariff(area);
export const serializeDeliveryAreaList = (area) => area.map(serializeDeliveryArea);
