import * as actions from './actions';
import mutations from './mutations';
import * as getters from './getters';

const state = {
  authServiceIsReady: false,
  token: null,
  deliveryTariffs: [],
  cities: [],
  citiesShort: [],
};

export default {
  state,
  getters,
  mutations,
  actions,
};
