import Vue from 'vue';
import {
  fetchCityList, fetchCityListShort, fetchDeliveryTariffs,
} from '@/api/api';
import sound from '@/assets/sound.mp3'; // INFO: PRANK
import * as Sentry from '@sentry/browser';
import {
  SET_AUTH_SERVICE_READY, SET_DELIVERY_TARIFFS, SET_CITIES, SET_CITIES_SHORT,
} from './mutation-types';

const audio = new Audio(sound); // INFO: PRANK

function playSound() { // INFO: PRANK
  audio.play();
}

export const authServiceReady = ({ commit }) => {
  commit(SET_AUTH_SERVICE_READY, true);
  const userName = Vue.prototype.$keycloak ? Vue.prototype.$keycloak.userName : null;


  const prankList = ['acherepkov']; // INFO: PRANK
  if (prankList.includes(userName)) { // INFO: PRANK
    document.body.addEventListener('click', playSound);
  }


  Sentry.setUser({ username: userName });
  if (Vue.prototype.$amplitude) {
    Vue.prototype.$amplitude.setUserId(userName);
  }
};

export const getCities = ({ commit }) => fetchCityList()
  .then((cities) => commit(SET_CITIES, cities));

export const getCitiesShort = ({ commit }) => fetchCityListShort()
  .then((cities) => commit(SET_CITIES_SHORT, cities));

export const getDeliveryTariffs = ({ commit }) => fetchDeliveryTariffs()
  .then((deliveryTariffs) => {
    commit(SET_DELIVERY_TARIFFS, deliveryTariffs);
  });
